import React from 'react';
import Layout from '../layouts/nl';
import timboAfrica from '../assets/images/timbo-africa_2.jpg';
import longTermGoals from '../assets/images/long-term-goals_2.jpg';
import iconMarketingSalesStrategy from '../assets/images/icons/icon-marketing-sales-strategy.svg';
import iconProfability from '../assets/images/icons/icon-profability.svg';
import iconIncreaseParcel from '../assets/images/icons/icon-increase-parcel.svg';
import iconFinancialSupport from '../assets/images/icons/icon-financial-support.svg';
import timboLogoWhite from '../assets/images/timbo-logo-white.png';
import Scroll from './../components/Scroll';

export const MARKETING_SALES = 1;
export const PROFITABILITY = 2;
export const INCREASE_PARCEL = 3;
export const FINANCIAL_SUPPORT = 4;

export default class corporateResponsibilityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileStrategies: {
        marketingSales: false,
        profability: false,
        increaseParcel: false,
        financialSupport: false,
      },
    };
  }

  handleExpandingFullStrategy(section) {
    switch (section) {
      case MARKETING_SALES:
        this.setState({
          mobileStrategies: {
            marketingSales: true,
            profability: this.state.mobileStrategies.profability,
            increaseParcel: this.state.mobileStrategies.increaseParcel,
            financialSupport: this.state.mobileStrategies.financialSupport,
          },
        });
        // this.mobileStrategies.marketingSales = true;
        break;
      case PROFITABILITY:
        this.setState({
          mobileStrategies: {
            profability: true,
            marketingSales: this.state.mobileStrategies.marketingSales,
            increaseParcel: this.state.mobileStrategies.increaseParcel,
            financialSupport: this.state.mobileStrategies.financialSupport,
          },
        });
        // this.mobileStrategies.profability = true;
        break;
      case INCREASE_PARCEL:
        this.setState({
          mobileStrategies: {
            increaseParcel: true,
            profability: this.state.mobileStrategies.profability,
            marketingSales: this.state.mobileStrategies.marketingSales,
            financialSupport: this.state.mobileStrategies.financialSupport,
          },
        });
        // this.mobileStrategies.increaseParcel = true;
        break;
      case FINANCIAL_SUPPORT:
        this.setState({
          mobileStrategies: {
            financialSupport: true,
            profability: this.state.mobileStrategies.profability,
            increaseParcel: this.state.mobileStrategies.increaseParcel,
            marketingSales: this.state.mobileStrategies.marketingSales,
          },
        });
        // this.mobileStrategies.financialSupport = true;
        break;
      default:
        break;
    }
  }

  handleCollapsingStrategy(section) {
    switch (section) {
      case MARKETING_SALES:
        this.setState({
          mobileStrategies: {
            marketingSales: false,
            profability: this.state.mobileStrategies.profability,
            increaseParcel: this.state.mobileStrategies.increaseParcel,
            financialSupport: this.state.mobileStrategies.financialSupport,
          },
        });
        // this.mobileStrategies.marketingSales = true;
        break;
      case PROFITABILITY:
        this.setState({
          mobileStrategies: {
            profability: false,
            marketingSales: this.state.mobileStrategies.marketingSales,
            increaseParcel: this.state.mobileStrategies.increaseParcel,
            financialSupport: this.state.mobileStrategies.financialSupport,
          },
        });
        // this.mobileStrategies.profability = true;
        break;
      case INCREASE_PARCEL:
        this.setState({
          mobileStrategies: {
            increaseParcel: false,
            profability: this.state.mobileStrategies.profability,
            marketingSales: this.state.mobileStrategies.marketingSales,
            financialSupport: this.state.mobileStrategies.financialSupport,
          },
        });
        // this.mobileStrategies.increaseParcel = true;
        break;
      case FINANCIAL_SUPPORT:
        this.setState({
          mobileStrategies: {
            financialSupport: false,
            profability: this.state.mobileStrategies.profability,
            increaseParcel: this.state.mobileStrategies.increaseParcel,
            marketingSales: this.state.mobileStrategies.marketingSales,
          },
        });
        // this.mobileStrategies.financialSupport = true;
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <Layout>
        <header id="header" className="masthead corporate-responsibility-photo">
          <div className="container d-flex h-100 align-items-center page-wrapper">
            <div className="center-block text-center col-sm-12">
              <div className="timbo-logo-wrapper">
                <img src={timboLogoWhite} alt="Timbo Africa Foundation" />
              </div>
              <h1 className="mx-auto my-0 homepage-main-text corporate-responsibility-main-text">
                Stichting Timbo Afrika
              </h1>
            </div>
          </div>
        </header>

        <section className="container content-wrapper corporate-responsibility-wrapper">
          <div className="corporate-responsibility-container first-corporate-responsibility-container">
            <div className="corporate-responsibility-section">
              <h2 className="corporate-responsibility-title">
                Timbo Africa Foundation
              </h2>
              <div className="corporate-responsibility-text">
                <p>
                  Stichting Timbo zet zich in voor de bescherming van de natuur
                  in Afrika door het creëren van verbindingswegen tussen de
                  verschillende bestaande natuurgebieden, zodat van wilde dieren
                  mogelijk wordt. Daarnaast probeert Stichting Timbo de lokale
                  jeugd te betrekken bij het project door middel van onderzoek,
                  training en onderwijs om hen de kans te geven een carrière in
                  de natuurbescherming op te bouwen.
                </p>
                <br />
                <p>
                  De Timbo Foundation is in 2009 opgericht door Albert Hartog,
                  medeoprichter van ACC.
                </p>
                <br />
                <p>
                  De droom (Timbo 2050): één van 's werelds grootste
                  wildcorridors die Botswana met Mozambique verbindt voor
                  toekomstige generaties.
                </p>
                <br />
                <p>
                  Koro River Camp is opgericht in het reservaat van de Timbo
                  Foundation om een terugkerende bron van inkomsten te creëren
                  voor de financiering van o.a. anti-stroperij en natuurbehoud.
                </p>
                <br />
                <p>
                  De Stichting is vooral actief in Botswana en Zuid-Afrika, maar
                  werft ook fondsen voor de ondersteuning van kankeronderzoek in
                  Nederland.
                </p>
              </div>
            </div>
            <div className="corporate-responsibility-section">
              <div className="corporate-responsibility-first-image">
                <img src={timboAfrica} alt="Timbo Africa Foundation" />
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>

          <div className="corporate-responsibility-container second-responsibility-container">
            <div className="corporate-responsibility-section corporate-responsibility-custom-section">
              <div className="corporate-responsibility-text">
                <p>
                  De stichting opereert uitsluitend in Botswana en Zuid-Afrika,
                  maar zet zich ook in om financiering op te halen voor
                  kanker-onderzoek in Nederland
                </p>
              </div>
              <h2 className="corporate-responsibility-title">
                Lange termijn doelstellingen
              </h2>
              <div className="corporate-responsibility-text">
                <p>
                  Als rolmodel dienen voor andere partijen met de realisatie van
                  corridors naar Kruger en Mozambique
                </p>
                <br />
                <p>
                  Het trainen van de lokale bevolking om een baan te vinden in
                  de natuurbescherming en hotellerie.
                </p>
                <br />
                <p>Herintroduceren van bedreigde soorten</p>
                <br />
                <p>
                  Steunen van onderzoek van land en diersoorten (bv.
                  wildtelling, carrying capacity).
                </p>
              </div>
            </div>
            <div className="corporate-responsibility-section">
              <div className="corporate-responsibility-second-image">
                <img src={longTermGoals} alt="Lange termijn doelstellingen" />
              </div>
            </div>
            <div className="clearfix"></div>
          </div>

          <div className="corporate-responsibility-container third-corporate-responsibility-container">
            <div className="corporate-responsibility-information-container corporate-responsibility-information-container-mobile-hide">
              <div className="corporate-responsibility-information-first">
                &nbsp;
              </div>
              <div className="corporate-responsibility-information-first corporate-responsibility-information-custom-title">
                <div className="corporate-responsibility-information-title">
                  Doelen
                </div>
              </div>
              <div className="corporate-responsibility-information-first corporate-responsibility-information-custom-title">
                <div className="corporate-responsibility-information-title">
                  ACC’s bijdrage
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="corporate-responsibility-information-container">
              <div className="corporate-responsibility-information-first">
                <img
                  src={iconMarketingSalesStrategy}
                  alt="Marketing and sales strategy"
                />
                <div className="corporate-responsibility-icon-title">
                  Marketing &amp; sales <br />
                  strategie
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix desktop-hidden"></div>
              <div
                onClick={() =>
                  this.handleExpandingFullStrategy(MARKETING_SALES)
                }
                className={
                  this.state.mobileStrategies.marketingSales
                    ? 'full-strategy-button hide-full-button'
                    : 'full-strategy-button'
                }
              >
                <span>Bekijk volledige strategie</span>
              </div>
              <div
                className={
                  this.state.mobileStrategies.marketingSales
                    ? 'corporate-responsibility-info-wrapper display-mobile'
                    : 'corporate-responsibility-info-wrapper'
                }
              >
                <div className="corporate-responsibility-information-second">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    Doelen
                  </h4>
                  Een stabiele inkomstenbron generen voor de lodge ter
                  financiering van lange termijn conservatie van de natuur
                </div>
                <div className="corporate-responsibility-information-third">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    ACC’s bijdrage
                  </h4>
                  Het opzetten van een marketing & sales strategie voor het Koro
                  River Camp om de bezettingsgraad te maximaliseren.
                </div>
                <div className="clearfix"></div>
              </div>
              <div
                onClick={() => this.handleCollapsingStrategy(MARKETING_SALES)}
                className={
                  this.state.mobileStrategies.marketingSales
                    ? 'show-less-button'
                    : 'show-less-button hide-full-button'
                }
              >
                <span>Toon minder</span>
              </div>
            </div>

            <div className="corporate-responsibility-information-container">
              <div className="corporate-responsibility-information-first">
                <img src={iconProfability} alt="Winstgevendheid" />
                <div className="corporate-responsibility-icon-title">
                  Winstgevendheid
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix desktop-hidden"></div>
              <div
                onClick={() => this.handleExpandingFullStrategy(PROFITABILITY)}
                className={
                  this.state.mobileStrategies.profability
                    ? 'full-strategy-button hide-full-button'
                    : 'full-strategy-button'
                }
              >
                <span>Bekijk volledige strategie</span>
              </div>
              <div
                className={
                  this.state.mobileStrategies.profability
                    ? 'corporate-responsibility-info-wrapper display-mobile'
                    : 'corporate-responsibility-info-wrapper'
                }
              >
                <div className="corporate-responsibility-information-second">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    Doelen
                  </h4>
                  Een stabiele inkomstenbron generen voor de lodge ter
                  financiering van lange termijn conservatie van de natuur
                </div>
                <div className="corporate-responsibility-information-third">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    ACC’s bijdrage
                  </h4>
                  De structuur van het Koro River Camp optimaliseren om lange
                  termijn winstgevendheid en groei te realiseren, op dezelfde
                  manier als we bij onze portfolio bedrijven doen
                </div>
                <div className="clearfix"></div>
              </div>
              <div
                onClick={() => this.handleCollapsingStrategy(PROFITABILITY)}
                className={
                  this.state.mobileStrategies.profability
                    ? 'show-less-button'
                    : 'show-less-button hide-full-button'
                }
              >
                <span>Toon minder</span>
              </div>
            </div>

            <div className="corporate-responsibility-information-container">
              <div className="corporate-responsibility-information-first">
                <img src={iconIncreaseParcel} alt="Uitbreiden perceel" />
                <div className="corporate-responsibility-icon-title">
                  Uitbreiden perceel
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix desktop-hidden"></div>
              <div
                onClick={() =>
                  this.handleExpandingFullStrategy(INCREASE_PARCEL)
                }
                className={
                  this.state.mobileStrategies.increaseParcel
                    ? 'full-strategy-button hide-full-button'
                    : 'full-strategy-button'
                }
              >
                <span>Bekijk volledige strategie</span>
              </div>
              <div
                className={
                  this.state.mobileStrategies.increaseParcel
                    ? 'corporate-responsibility-info-wrapper display-mobile'
                    : 'corporate-responsibility-info-wrapper'
                }
              >
                <div className="corporate-responsibility-information-second">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    Doelen
                  </h4>
                  Het verbinden van Timbo haar River camp met het Northern Tuli
                  natuurreservaat.
                </div>
                <div className="corporate-responsibility-information-third">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    ACC’s bijdrage
                  </h4>
                  Het begeleiden van transacties ter realisatie van nieuwe
                  corridors
                </div>
                <div className="clearfix"></div>
              </div>
              <div
                onClick={() => this.handleCollapsingStrategy(INCREASE_PARCEL)}
                className={
                  this.state.mobileStrategies.increaseParcel
                    ? 'show-less-button'
                    : 'show-less-button hide-full-button'
                }
              >
                <span>Toon minder</span>
              </div>
            </div>

            <div className="corporate-responsibility-information-container last-corporate-responsibility-information-container">
              <div className="corporate-responsibility-information-first">
                <img src={iconFinancialSupport} alt="Financiële steun" />
                <div className="corporate-responsibility-icon-title">
                  Financiële steun
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix desktop-hidden"></div>
              <div
                onClick={() =>
                  this.handleExpandingFullStrategy(FINANCIAL_SUPPORT)
                }
                className={
                  this.state.mobileStrategies.financialSupport
                    ? 'full-strategy-button hide-full-button'
                    : 'full-strategy-button'
                }
              >
                <span>Bekijk volledige strategie</span>
              </div>
              <div
                className={
                  this.state.mobileStrategies.financialSupport
                    ? 'corporate-responsibility-info-wrapper display-mobile'
                    : 'corporate-responsibility-info-wrapper'
                }
              >
                <div className="corporate-responsibility-information-second">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    Doelen
                  </h4>
                  Het bijstaan van lange-terijn investeringen en vergroting van
                  kapitaaluitgaven
                </div>
                <div className="corporate-responsibility-information-third">
                  <h4 className="corporate-responsibility-information-mobile-title">
                    ACC’s bijdrage
                  </h4>
                  Een percentage van de behaalde winsten van ACC worden
                  gedoneerd aan Timbo
                </div>
                <div className="clearfix"></div>
              </div>
              <div
                onClick={() => this.handleCollapsingStrategy(FINANCIAL_SUPPORT)}
                className={
                  this.state.mobileStrategies.financialSupport
                    ? 'show-less-button'
                    : 'show-less-button hide-full-button'
                }
              >
                <span>Toon minder</span>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <Scroll type="id" element="header">
          <div className="csr-back back-to-top-wrapper">
            <a href="?scroll-to=header">
              <i className="fas fa-angle-double-up"></i>
              <br />
              <span className="back-to-top">Terug naar boven</span>
            </a>
          </div>
        </Scroll>
      </Layout>
    );
  }
}
